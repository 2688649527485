import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, object, shape, string } from 'prop-types';

import { Footer, Hero, SEO } from '../../components';
import { Link, Icon } from '../../components/elements';

const CompatibilityPage = ({ data }) => {
  const { seo, path, heading, subheading, headerImage, theme } = data.allGraphCmsPage.edges[0].node;
  const checkByMakeCta = {
    text: 'Check by make, model, year',
    path: '/compatibility/makes/',
  };

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero heading={heading} subheading={subheading} image={headerImage} theme={theme} />
      <div className="container bg-white md:px-12 lg:px-24 pt-8 pb-20 md:mt-24 md:py-16 space-y-2 md:space-y-4 max-w-3xl">
        <Link
          path={checkByMakeCta.path}
          classnames="link-cta"
          segmentProperties={{
            section: 'article',
            style: 'cta secondary',
            text: checkByMakeCta.text,
            path: checkByMakeCta.path,
          }}
        >
          {checkByMakeCta.text} <Icon name="arrow" />
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default CompatibilityPage;

CompatibilityPage.propTypes = {
  data: shape({
    allGraphCmsPage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            path: string.isRequired,
            heading: string.isRequired,
            subheading: string.isRequired,
            headerImage: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
            seo: shape({
              title: string.isRequired,
              metaDescription: string.isRequired,
            }).isRequired,
            theme: string,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const CompatibilityPageQuery = graphql`
  query CompatibilityPage {
    allGraphCmsPage(filter: { path: { eq: "compatibility" } }) {
      edges {
        node {
          heading
          subheading
          path
          headerImage {
            gatsbyImageData(placeholder: NONE)
          }
          seo {
            title
            metaDescription
          }
          theme
        }
      }
    }
  }
`;
